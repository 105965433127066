import { render, staticRenderFns } from "./y_choose_link.vue?vue&type=template&id=1b32a65e&scoped=true"
import script from "./y_choose_link.vue?vue&type=script&lang=js"
export * from "./y_choose_link.vue?vue&type=script&lang=js"
import style0 from "./y_choose_link.vue?vue&type=style&index=0&id=1b32a65e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b32a65e",
  null
  
)

export default component.exports